import React from 'react';
import OverviewBrick from "./OverviewBrick";
import Error from "./Error";

import './Overview.css';

function Overview(props) {

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className="annotation">
                    <label>{props.introduction}</label>
                    <h1>{props.title}</h1>
                </div>
                {
                    props.bricks &&
                    <div className="bricks">
                        {
                            props.bricks.map((bricksData, i) => <OverviewBrick key={`overviewBrick${i}`}
                                                                               data={bricksData}/>)
                        }
                    </div>
                }
                {props.error && <Error error={props.error}/>}
            </React.Fragment>
        );
    }

    return (
        <div className="Overview">
            {renderContent()}
            {props.children}
        </div>
    );
}

export default Overview;