import * as React from "react";

import VehicleForm from "../components/VehicleForm";
import Overview from "../components/Overview";

import './VehicleAdd.css';

function VehicleAdd(props) {

    return (
        <div className="VehicleAdd">
            <Overview
                introduction="Vítejte v administračním prostředí pro správu a plánování cestovních informací značky Rozvozovka.cz"
                title="Nový vůz"
            >
                <VehicleForm
                    history={props.history}
                />
            </Overview>
        </div>
    );
}

export default VehicleAdd;
