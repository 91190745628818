import {useState} from "react";
import * as React from "react";
import Logo from '../img/logo-1.png';
import Forms from '../form/Forms';
import AuthService from "../AuthService";
import Error from "../components/Error";
import './Login.css';
import t from "../hooks/useTranslation";
import {useAuth} from "../context/AuthContext";
import config from "../config/config";
import {useHistory, useLocation} from "react-router-dom";

const authService = new AuthService();

function ResetPasswordContent() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const id = params.get('id');

    const history = useHistory();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const {login} = useAuth();

    const submit = async () => {
        try {
            const {accessToken, refreshToken} = await authService.setNewPassword({id, token, password, passwordAgain});
            setError(null);
            login(accessToken, refreshToken);
            history.push(config.routeMap.dashboard);
        } catch (err) {
            setError(err);
        }
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            submit();
        }
    }

    return (
        <div className="left-container">

            <img className="logo" src={Logo} alt="logo"/>

            <h2>{t('resetPasswordTitle')}</h2>

            <div className="input-container">
                <label>
                    {t('password')}
                    <input type="password" name="password" value={password}
                           onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
                </label>
            </div>

            <div className="input-container">
                <label>
                    {t('passwordAgain')}
                    <input type="password" name="passwordAgain" value={passwordAgain}
                           onChange={e => setPasswordAgain(e.target.value)} onKeyPress={handleKeyPress}/>
                </label>
            </div>

            <div className="login-row">
                <div className="remember"/>

                <label className="forgotten-password"
                       onClick={() => history.push(config.routeMap.login)}>{t('backToLogin')}</label>
            </div>

            <Forms.LoginButton label={t('setNewPassword')} onClick={submit}/>
            {error && <Error error={error}/>}


        </div>
    );
}

export default ResetPasswordContent;
