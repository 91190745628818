import * as React from "react";

import config from "../config/config";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import VehicleMaintenanceForm from "../components/VehicleMaintenanceForm";

import './VehicleMaintenanceDetail.css';
import t from "../hooks/useTranslation";

const INITIAL_BRICKS_DATA = [
    {
        label: 'Celkem vozidel',
        isLoading: true,
        dataKey: 'vehicleCount'
    },
    {
        label: 'Náklady na PHM (Kč)',
        isLoading: true,
        valueRenderer: ({gasPriceCount}) => `${gasPriceCount},-`
    },
    {
        label: 'Náklady ostatní (Kč)',
        isLoading: true,
        valueRenderer: ({otherPriceCount}) => `${otherPriceCount},-`
    },
    {
        label: 'Náklady celkem (s DPH)',
        isLoading: true,
        valueRenderer: ({totalPriceCount}) => `${totalPriceCount},-`
    }
];

function VehicleMaintenanceDetail(props) {
    const {
        isLoading,
        error,
        response,
        bricksData,
        setBrickDataByDataKey
    } = useOverviewData(config.apiRouteMap.getVehicleMaintenance(props.match.params.id), INITIAL_BRICKS_DATA);

    const _getOverviewTitle = () => {
        return (!isLoading && response) ? t('maintenanceForVehicleSPZ', {"%spz": response.data.vehicle.licenceNumber}) : t('maintenanceForVehicle');
    }

    return (
        <div className="VehicleMaintenanceDetail">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={_getOverviewTitle()}
                bricks={bricksData}
                error={error}
            >
                <VehicleMaintenanceForm
                    setBrickDataByDataKey={setBrickDataByDataKey}
                    history={props.history}
                    data={response?.data}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default VehicleMaintenanceDetail;
