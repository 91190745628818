import './Button.css';

function Button(props) {
    let className = "Button";

    // custom lass
    if (props.className) {
        className += ` ${props.className}`;
    }

    // dark class
    if (props.dark) {
        className += ` dark`;
    }

    // disabled class
    if (props.disabled) {
        className += ` disabled`;
    }

    const disabled = props.disabled || false;
    return (
        <button className={className} onClick={props.onClick} disabled={disabled}>
            {props.label}
        </button>
    );
}

export default Button;