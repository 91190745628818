import * as React from "react";
import google from '../img/google_get_it_on.png';
import t from "../hooks/useTranslation";
import LoginContent from "./LoginContent";
import ForgottenPasswordContent from "./ForgottenPasswordContent";
import {useParams} from "react-router-dom";
import config from "../config/config";
import ResetPasswordContent from "./ResetPasswordContent";

import './Login.css';

function Login() {
    const {action} = useParams();

    return (
        <div className="login-route">
            <div className="absolute">
                <div className="gradient right-container"/>
                <div className="overlay"/>
            </div>

            <div className="absolute">
                {`/${action}` === config.routeMap.forgottenPassword && <ForgottenPasswordContent/>}
                {`/${action}` === config.routeMap.resetPassword && <ResetPasswordContent/>}
                {!action && <LoginContent/>}

                <div className="right-container">
                    <div className="vehicles-under-control">
                        <label className="header-text" dangerouslySetInnerHTML={{__html: t('loginText')}}/>
                        <img src={google} alt="get-it-on-google"/>
                    </div>
                    <div className="annotation">
                        <div>
                            <label>Rozvozovka.cz</label>
                            <label>Ladexgroup.cz</label>
                        </div>
                        <p>{t('appProvider')}</p>
                        <p>{t('appInfo')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
