import * as React from "react";

import config from "../config/config";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import VehicleMaintenanceForm from "../components/VehicleMaintenanceForm";

import './VehicleMaintenanceAdd.css';

const INITIAL_BRICKS_DATA = [
    {
        label: 'Celkem vozidel',
        isLoading: true,
        dataKey: 'vehicleCount'
    },
    {
        label: 'Náklady na PHM (Kč)',
        isLoading: true,
        dataKey: 'gasPriceCount',
        valueRenderer: val => `${val},-`
    },
    {
        label: 'Náklady ostatní (Kč)',
        isLoading: true,
        dataKey: 'otherPriceCount',
        valueRenderer: val => `${val},-`
    },
    {
        label: 'Náklady celkem (s DPH)',
        isLoading: true,
        dataKey: 'totalPriceCount',
        valueRenderer: val => `${val},-`
    }
];

function VehicleMaintenanceAdd(props) {

    return (
        <div className="VehicleMaintenanceAdd">
            <Overview
                introduction="Vítejte v administračním prostředí pro správu a plánování cestovních informací značky Rozvozovka.cz"
                title="Nový náklad"
            >
                <VehicleMaintenanceForm
                    history={props.history}
                />
            </Overview>
        </div>
    );
}

export default VehicleMaintenanceAdd;
