import React, {useEffect, useState} from 'react';
import jwtDecode from "jwt-decode";
import AuthService from "../AuthService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Loading from "./Loading";
import {useAuth} from "../context/AuthContext";

import './LoggedUser.css';
import {useHistory} from "react-router-dom";
import config from "../config/config";

const authService = new AuthService();

function LoggedUser() {
    const history = useHistory();
    const {getToken, logout} = useAuth();
    const [user, setUser] = useState();
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.get(`/api/user/${jwtDecode(getToken())._id}`);
                setUser(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
            }
        })();
    }, []);

    const handleLogout = () => {
        logout();
        history.push(config.routeMap.login);
    }

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className="container">
                    <label>{`${user.name} ${user.surname}`}</label>
                    <label>{user.email}</label>
                </div>
                <div className="icon" onClick={handleLogout}>
                    <FontAwesomeIcon icon="user-circle" size="2x"/>
                </div>
            </React.Fragment>
        );
    }

    const getContent = () => {
        return isLoading ? <Loading/> : renderContent();
    }

    return (
        <div className="logged-user">
            {getContent()}
        </div>
    );
}

export default LoggedUser;