import React from 'react';
import './Error.css';

export default function Error({error}) {
    let message = '';
    if (error.response?.data?.errorCode) {
        message = `Error code ${error.response.status} (${error.response.statusText}): ${error.response.data.errorCode}`;
    } else if (error?.response?.data) {
        let {data} = error.response;
        message = `${error.message}: ${data.errorMessage}`;
    } else {
        message = error.message;
    }

    return (
        <div className="Error alert alert-danger" role="alert">
            {message}
        </div>
    );
}
