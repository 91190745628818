import * as React from "react";
import Overview from "../components/Overview";
import useOverviewData from "../hooks/useOverviewData";
import config from "../config/config";

import './Drivers.css';
import OverviewTable from "../components/OverviewTable";
import {Link} from "react-router-dom";
import moment from "moment";
import t from "../hooks/useTranslation";

const INITIAL_BRICKS_DATA = [
    {
        label: 'Celkem řidičů',
        isLoading: true,
        dataKey: 'driverCount'
    },
    {
        label: 'Úvazek HPP',
        isLoading: true,
        dataKey: 'driverHppCount'
    },
    {
        label: 'Úvazek DPP',
        isLoading: true,
        dataKey: 'driverDppCount'
    },
    {
        label: 'Celkem najeto (km)',
        isLoading: true,
        dataKey: 'totalDistanceCount'
    }
];

function Drivers() {
    const {isLoading, error, response, bricksData} = useOverviewData('/api/driver/dashboard', INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'personalNumber',
            title: t('personalNumber'),
        },
        {
            key: 'name',
            title: t('name'),
            valueRenderer: ({name, surname, _id}) => <Link to={config.routeMap.getDriverDetail(_id)}>
                {`${name} ${surname}`}
            </Link>
        },
        {
            key: 'jobPosition',
            title: t('jobPosition'),
            valueRenderer: ({profileList}) => profileList.map(role => t(`role${role}`)).join(', ')
        },
        {
            key: 'journeyCount',
            title: t('rideCount')
        },
        {
            key: 'hiredDate',
            title: t('hiredDate'),
            valueRenderer: ({hiredDate}) => hiredDate && `${moment(hiredDate).format('DD.MM.YYYY')}`
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="action1" to={config.routeMap.getDriverDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        {
            component: <Link to={config.routeMap.addDriver}>{t('newDriver')}</Link>
        }
    ];

    return (
        <div className="Drivers">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('driversAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('driversList')} columns={columns}
                               rows={response?.overviewData}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Drivers;
