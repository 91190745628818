import * as React from "react";
import './Select.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Select(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const getOptions = () => {
        const options = props.options;
        if (props.emptyOption) {
            options.unshift({value: null, label: ''});
        }
        return React.Children.toArray(options.map(opt => <option value={opt.value}>{opt.label}</option>));
    }

    const disabled = props.disabled || false;
    return (
        <label className="FormSelect">
            {props.label}
            <select {...getMainProps(inputMainProps)} disabled={disabled}>
                {getOptions()}
            </select>
        </label>
    );
}

export default Select;