const config = {
    routeMap: {
        dashboard: '/dashboard',
        vehicles: '/vehicles',
        vehiclesMaintenance: '/vehicles-maintenance',
        drivers: '/drivers',
        clients: '/clients',
        routes: '/routes',
        getRouteHistoryDetail: id => `/route-history/detail/${id}`,
        getVehicleDetail: id => `/vehicle/edit/${id}`,
        getClientDetail: id => `/client/edit/${id}`,
        getRouteDetail: id => `/route/edit/${id}`,
        getDriverDetail: id => `/driver/edit/${id}`,
        getVehicleMaintenanceDetail: id => `/vehicle-maintenance/edit/${id}`,

        addDriver: '/driver/add',
        addVehicle: '/vehicle/add',
        addVehicleMaintenance: '/vehicle-maintenance/add',
        addClient: '/client/add',
        addRoute: '/route/add',

        editDriver: '/driver/edit/:id',
        editClient: '/client/edit/:id',
        editRoute: '/route/edit/:id',
        editVehicle: '/vehicle/edit/:id',
        editVehicleMaintenance: '/vehicle-maintenance/edit/:id',

        // non-auth routes
        login: '/',
        forgottenPassword: '/forgotten-password',
        resetPassword: '/reset-password',
    },
    apiRouteMap: {
        vehicleMaintenanceOverview: '/api/vehicle-maintenance/dashboard',
        vehicle: '/api/vehicle',
        route: '/api/route',
        driver: '/api/user/createDriver',
        client: '/api/client',
        vehicleMaintenance: '/api/vehicle-maintenance',
        listDrivers: `/api/user/listDrivers`,
        getVehicle: id => `/api/vehicle/${id}`,
        getDriver: id => `/api/user/${id}`,
        getRoute: id => `/api/route/${id}`,
        getClient: id => `/api/client/${id}`,
        getVehicleMaintenance: id => `/api/vehicle-maintenance/${id}`
    },
    fuelMap: {
        gas: 'Benzín',
        diesel: 'Nafta',
        lpg: 'LPG',
        cng: 'CNG'
    },
    menuItems: {
        '/dashboard': ['/dashboard'],
        '/vehicles-maintenance': ['/vehicles-maintenance', '/vehicle-maintenance/add', '/vehicle-maintenance/edit/[a-f\\d]{24}'],
        '/vehicles': ['/vehicles', '/vehicle/edit/[a-f\\d]{24}', '/vehicle/add'],
        '/drivers': ['/drivers', '/driver/add', '/driver/edit/[a-f\\d]{24}'],
        '/clients': ['/clients', '/client/add', '/client/edit/[a-f\\d]{24}'],
        '/routes': ['/routes', '/route/add', '/route/edit/[a-f\\d]{24}']
    }
};

export default config;