import {useEffect, useState} from 'react';
import useFetch from "./useFetch";

export default function useOverviewData(url, initialBricksData) {
    const [bricksData, setBricksData] = useState(initialBricksData);
    const {isLoading, error, response} = useFetch(url, 'GET');

    useEffect(() => {
        let newBricksData = bricksData.map(brickData => {
            // handle response
            if (response) {
                if (brickData.valueRenderer) {
                    brickData.data = brickData.valueRenderer(response);
                } else {
                    brickData.data = response[brickData.dataKey];
                }
            } else {
                // handle error
                brickData.data = 'Error';
            }
            brickData.isLoading = isLoading;
            return brickData;
        });
        setBricksData(newBricksData);
    }, [isLoading]);

    const setBrickDataByDataKey = (dataKey, value) => {
        const newBricksData = bricksData.map(brickData => {
            if (brickData.dataKey === dataKey) {
                brickData.data = value;
            }
            return brickData;
        });
        setBricksData(newBricksData);
    }

    return {
        isLoading,
        error,
        response,
        bricksData,
        setBrickDataByDataKey
    }
}
