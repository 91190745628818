import React, {useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Forms from "./Forms";

import "./RouteStopSelector.css";

function RouteStopSelector({stopList: allStops, selectedStopList: selectedStops, onChange, className = ''}) {

    const [selectedStopList, setSelectedStopList] = useState(selectedStops.map((selectedStop) => {
        const matchingStop = allStops.find((stop) => stop._id === selectedStop.client);
        if (matchingStop) {
            return {
                ...matchingStop,
                note: selectedStop.note || '',
            };
        }
        return selectedStop;
    }));
    const [stopList, setStopList] = useState(allStops.filter(stop => !selectedStops.find(selectedStop => selectedStop.client === stop._id)));

    const [searchAllStops, setSearchAllStops] = useState("");
    const [searchSelectedStops, setSearchSelectedStops] = useState("");

    const filterStops = (stop, searchString) => {
        const searchLower = searchString.toLowerCase();
        const {name, address} = stop;
        if (!address) return 'Adresa nevyplněna';
        const {street, houseNumber, city} = address;
        return `${name.toLowerCase()} ${street.toLowerCase()} ${houseNumber.toString()}, ${city.toLowerCase()}`.includes(searchLower);
    };

    const filteredAllStops = stopList.filter(stop => filterStops(stop, searchAllStops));
    const filteredSelectedStops = selectedStopList.filter(stop => filterStops(stop, searchSelectedStops));
    const [noteInputs, setNoteInputs] = useState({});

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const newSelectedStopList = [...selectedStopList];
        if (result.source.droppableId === 'allStops' && result.destination.droppableId === 'selectedStops') {
            const stop = stopList[result.source.index];
            const newStopList = [...stopList];
            newStopList.splice(result.source.index, 1);

            newSelectedStopList.splice(result.destination.index, 0, stop);
            setStopList(newStopList);
        } else if (result.source.droppableId === 'selectedStops' && result.destination.droppableId === 'allStops') {
            const stop = selectedStopList[result.source.index];
            newSelectedStopList.splice(result.source.index, 1);

            const newStopList = [...stopList];
            newStopList.splice(result.destination.index, 0, stop);

            setStopList(newStopList);
        } else if (result.source.droppableId === 'selectedStops' && result.destination.droppableId === 'selectedStops') {
            const [reorderedStop] = newSelectedStopList.splice(result.source.index, 1);
            newSelectedStopList.splice(result.destination.index, 0, reorderedStop);
        }

        setSelectedStopList(newSelectedStopList);
        onChange(newSelectedStopList);
    };

    // const toggleNoteInput = (stopId) => {
    //     setNoteInputs((prevInputs) => ({
    //         ...prevInputs,
    //         [stopId]: !prevInputs[stopId],
    //     }));
    // };
    //
    // const updateNote = (stopId, note) => {
    //     setSelectedStopList((prevSelectedStops) => {
    //         const updatedList = prevSelectedStops.map((stop) =>
    //             stop._id === stopId ? {...stop, note} : stop
    //         );
    //         onChange(updatedList);
    //         return updatedList;
    //     });
    // };

    const printAddress = address => {
        if (!address) return 'Adresa nevyplněna';
        return `${address.street} ${address.houseNumber}, ${address.city}`;
    }

    return (
        <div className={`RouteStopSelector ${className}`}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div>
                    <h2>Trasa</h2>
                    <Forms.Text
                        placeholder="Vyhledat zastávky"
                        value={searchSelectedStops}
                        onChange={(e) => setSearchSelectedStops(e.target.value)}
                    />
                    <Droppable droppableId="selectedStops">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                {filteredSelectedStops.map(({_id, name, address}, index) => (
                                    <Draggable key={_id} draggableId={_id} index={index}>
                                        {(provided) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className="stop-item">
                                                    <span><strong>{name}</strong> - {printAddress(address)}</span>
                                                    {/*<div className="note-icon"*/}
                                                    {/*     onClick={() => toggleNoteInput(stopItem._id)}>*/}
                                                    {/*    <EditIcon/>*/}
                                                    {/*</div>*/}
                                                    {/*{stopItem.note || noteInputs[stopItem._id] ? (*/}
                                                    {/*    <input*/}
                                                    {/*        type="text"*/}
                                                    {/*        value={stopItem.note}*/}
                                                    {/*        onChange={(e) => updateNote(stopItem._id, e.target.value)}*/}
                                                    {/*        onBlur={() => toggleNoteInput(stopItem._id)}*/}
                                                    {/*    />*/}
                                                    {/*) : null}*/}
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </div>
                <div>
                    <h2>Všechny zastávky</h2>
                    <Forms.Text
                        placeholder="Vyhledat zastávky"
                        value={searchAllStops}
                        onChange={(e) => setSearchAllStops(e.target.value)}
                    />
                    <Droppable droppableId="allStops">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                {filteredAllStops.map(({_id, name, address}, index) => (
                                    <Draggable key={_id} draggableId={_id} index={index}>
                                        {(provided) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <strong>{name}</strong> - {printAddress(address)}
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </div>
    );
}

export default RouteStopSelector;