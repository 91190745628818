import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";

import './ClientForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    name: '',
    type: '',
    email: '',
    mobile: '',
    ico: '',
    dic: '',
    address: {
        city: '',
        street: '',
        streetNumber: '',
        zip: '',
        country: ''
    }
}

function ClientForm(props) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (formData._id) {
                await authService.put(config.apiRouteMap.getClient(formData._id), formData);
            } else {
                await authService.post(config.apiRouteMap.client, formData);
            }
            setError(null);
            props.history.push(config.routeMap.clients);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const typeOptions = [
        {label: t('company'), value: 'company'},
        {label: t('customer'), value: 'customer'},
        {label: t('depoA'), value: 'depoA'},
        {label: t('depoB'), value: 'depoB'},
    ]

    const renderForm = () => {
        console.log(formData);
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <Forms.Text label={t('name')} value={formData.name} name='name'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('type')} value={formData.type} name='type' disabled={isLoading}
                                      onChange={onChange(setFormData)} options={typeOptions}/>
                        <Forms.Text label={t('ico')} value={formData.ico} name='email' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('dic')} value={formData.dic} name='email' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('email')} value={formData.email} name='email' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('mobile')} value={formData.mobile} name='mobile' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.NestedForm name='address' disabled={isLoading} value={formData.address}
                                          onChange={onChange(setFormData)}>
                            <Forms.Text label={t('street')} name='street' disabled={isLoading}/>
                            <Forms.Text label={t('streetNumber')} name='streetNumber' disabled={isLoading}/>
                            <Forms.Text label={t('city')} name='city' disabled={isLoading}/>
                            <Forms.Text label={t('zip')} name='zip' disabled={isLoading}/>
                            <Forms.Text label={t('country')} name='country' disabled={isLoading}/>
                        </Forms.NestedForm>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="ClientForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default ClientForm;