import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import t from "../hooks/useTranslation";

import './DriverForm.css';

const authService = new AuthService();

const workingTimeOptions = [
    {label: '', value: null},
    {label: t('hpp'), value: 'hpp'},
    {label: t('dpp'), value: 'dpp'},
    {label: t('parttime'), value: 'parttime'}
];
const sexOptions = [
    {label: '', value: 'notSpecified'},
    {label: t('man'), value: 'man'},
    {label: t('woman'), value: 'female'},
];

const INITIAL_FORM_DATA = {
    name: '',
    surname: '',
    sex: '',
    email: '',
    mobile: '',
    personalNumber: '',
    address: {
        city: '',
        street: '',
        streetNumber: '',
        zip: '',
        country: ''
    },
    dateOfBirth: '',
    hiredDate: '',
    password: '',
    ac: true,
    role: ['driver'],
    workingTime: ''
}

function DriverForm(props) {
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            delete props.data.password;
            setFormData(props.data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (formData._id) {
                await authService.put(config.apiRouteMap.getDriver(formData._id), formData);
            } else {
                await authService.post(config.apiRouteMap.driver, formData);
            }
            setError(null);
            props.history.push(config.routeMap.drivers);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <Forms.Text label={t('name')} value={formData.name} name='name'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('surname')} value={formData.surname} name='surname' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Select label={(t('sex'))} value={formData.sex} name='sex' disabled={isLoading}
                                      onChange={onChange(setFormData)} options={sexOptions}/>
                        <Forms.Text label={t('email')} value={formData.email} name='email' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('mobile')} value={formData.mobile} name='mobile' disabled={isLoading}
                                    onChange={onChange(setFormData)}/>
                        <Forms.Number label={t('personalNumber')} value={formData.personalNumber}
                                      name='personalNumber' disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('workingTime')} value={formData.workingTime} name='workingTime'
                                      disabled={isLoading} options={workingTimeOptions}
                                      onChange={onChange(setFormData)}/>
                        <Forms.Date label={t('dateOfBirth')} value={formData.dateOfBirth} name="dateOfBirth"
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Date label={t('hiredDate')} value={formData.hiredDate} name="hiredDate"
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('setNewPassword_')} value={formData.password} name="password"
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.NestedForm name='address' disabled={isLoading} value={formData.address}
                                          onChange={onChange(setFormData)}>
                            <Forms.Text label={t('street')} name='street' disabled={isLoading}/>
                            <Forms.Text label={t('streetNumber')} name='streetNumber' disabled={isLoading}/>
                            <Forms.Text label={t('city')} name='city' disabled={isLoading}/>
                            <Forms.Text label={t('zip')} name='zip' disabled={isLoading}/>
                            <Forms.Text label={t('country')} name='country' disabled={isLoading}/>
                        </Forms.NestedForm>
                    </form>
                </div>
                {error && <Error error={error}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <Forms.Button label={t('save')} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="DriverForm">
            {props.isLoading ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default DriverForm;