import * as React from "react";
import config from "../config/config";

import {Link} from "react-router-dom";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import OverviewTable from "../components/OverviewTable";
import t from "../hooks/useTranslation";

import './Clients.css';

const INITIAL_BRICKS_DATA = [
    {
        label: 'Celkem tras',
        isLoading: true,
        dataKey: 'routeCount'
    },
    {
        label: 'Celkem zastávek',
        isLoading: true,
        dataKey: 'routeStopCount'
    },
    {
        label: 'Nevím co sem',
        isLoading: true,
        dataKey: 'clientCount'
    },
    {
        label: 'A ani sem',
        isLoading: true,
        dataKey: 'clientCount'
    }
];

function Clients() {
    const {isLoading, error, response, bricksData} = useOverviewData('/api/route/dashboard', INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'name',
            title: t('name'),
            linkRenderer: ({_id, name}) => ({
                route: config.routeMap.getRouteDetail(_id),
                label: name
            })
        },
        {
            key: 'stopCount',
            title: t('stopCount'),
            valueRenderer: ({stopList}) => stopList.length
        },
        {
            key: 'driver',
            title: t('driver'),
            valueRenderer: ({driver}) => `${driver.name} ${driver.surname}`
        },
        {
            key: 'averageDuration',
            title: t('averageDuration'),
            valueRenderer: ({averageDuration}) => averageDuration || '-'
        },
        {
            key: 'vozidlo',
            title: t('vehicle'),
            linkRenderer: ({vehicle}) => vehicle && ({
                route: config.routeMap.getVehicleDetail(vehicle._id),
                label: vehicle.licenceNumber
            })
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="action" to={config.routeMap.getRouteDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        {
            component: <Link to={config.routeMap.addRoute}>{t('newRoute')}</Link>
        }
    ];

    return (
        <div className="Routes">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('routesAdministration')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('routeList')} columns={columns}
                               rows={response?.overviewData}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Clients;
