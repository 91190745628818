import moment from "moment";
import * as React from "react";

import config from "../config/config";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import DriverForm from "../components/DriverForm";

import './DriverDetail.css';
import t from "../hooks/useTranslation";

const INITIAL_BRICKS_DATA = [
    {
        label: t('name'),
        isLoading: true,
        dataKey: 'name'
    },
    {
        label: t('workingTime'),
        isLoading: true,
        dataKey: 'workingTime',
        valueRenderer: ({workingTime}) => t(workingTime)
    },
    {
        label: t('ridesCount'),
        isLoading: true,
        dataKey: 'routeCount'
    },
    {
        label: t('hiredDate'),
        isLoading: true,
        valueRenderer: ({hiredDate}) => moment(hiredDate).format('DD.MM.YYYY')
    }
];

function DriverDetail(props) {
    const {
        isLoading,
        error,
        response,
        bricksData,
    } = useOverviewData(config.apiRouteMap.getDriver(props.match.params.id), INITIAL_BRICKS_DATA);

    return (
        <div className="DriverDetail">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('driverDetail')}
                bricks={bricksData}
                error={error}
            >
                <DriverForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default DriverDetail;
