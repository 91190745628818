import * as React from "react";
import Overview from "../components/Overview";
import t from "../hooks/useTranslation";
import RouteForm from "../components/RouteForm";

import './RouteAdd.css';

function RouteAdd(props) {

    return (
        <div className="RouteAdd">
            <Overview
                introduction={t('introductionText')}
                title={t('newRoute')}
            >
                <RouteForm
                    history={props.history}
                />
            </Overview>
        </div>
    );
}

export default RouteAdd;
