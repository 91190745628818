import * as React from "react";
import config from "../config/config";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import OverviewTable from "../components/OverviewTable";
import {Link} from "react-router-dom";
import t from "../hooks/useTranslation";
import AuthService from "../AuthService";
import Forms from "../form/Forms";
import {useEffect, useState} from "react";

import './VehiclesMaintenance.css';

const authService = new AuthService();

const INITIAL_BRICKS_DATA = [
    {
        label: t('vehicleCount'),
        isLoading: true,
        route: config.routeMap.vehicles,
        dataKey: 'vehicleCount'
    },
    {
        label: t('fuelPriceCount'),
        isLoading: true,
        valueRenderer: ({fuelPriceCount}) => `${fuelPriceCount.toFixed(2)},-`
    },
    {
        label: t('otherPriceCount'),
        isLoading: true,
        valueRenderer: ({otherPriceCount}) => `${otherPriceCount.toFixed(2)},-`
    },
    {
        label: t('totalPriceCount'),
        isLoading: true,
        valueRenderer: ({totalPriceCount}) => `${totalPriceCount.toFixed(2)},-`
    }
];

function VehiclesMaintenance() {
    const {
        isLoading,
        error,
        response,
        bricksData
    } = useOverviewData(config.apiRouteMap.vehicleMaintenanceOverview, INITIAL_BRICKS_DATA);
    const [filter, setFilter] = useState({});

    const columns = [
        {
            key: 'spz',
            title: t('spz'),
            linkRenderer: ({vehicle}) => ({
                route: config.routeMap.getVehicleDetail(vehicle._id),
                label: vehicle.licenceNumber
            })
        },
        {
            key: 'type',
            title: t('maintenanceType'),
            valueRenderer: ({type}) => t(type)
        },
        {
            key: 'description',
            title: t('description')
        },
        {
            key: 'priceWithVat',
            title: t('priceWithVat'),
            valueRenderer: ({priceWithVat}) => `${priceWithVat.toFixed(2)},-`
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="action" to={config.routeMap.getVehicleMaintenanceDetail(_id)}>
                {t('detail')}
            </Link>
        }
    ];

    const tableActions = [
        {
            component: <Link to={config.routeMap.addVehicleMaintenance}>{t('newMaintenance')}</Link>
        }
    ];

    const onFilterChange = async (e) => {
        setFilter({...filter, [e.target.name]: e.target.value});
    }

    useEffect(() => {
        (async () => {
            try {
                await authService.post(config.apiRouteMap.vehicleMaintenanceOverview, filter)
            } catch (e) {
                // setError(e);
            }
        })();
    }, [filter]);

    const getFilters = () => {
        if (!isLoading) {
            const vehicleOptions = response.filterValues.vehicleOptions.map(i => ({
                label: i.licenceNumber,
                value: i._id
            }));
            const typeOptions = response.filterValues.typeOptions.map(i => ({label: t(i), value: i}));
            return <div className="filters">
                <Forms.Date name='from' label="Od" min={response.filterValues.minDate}
                            max={response.filterValues.maxDate} onChange={onFilterChange}/>,
                <Forms.Date name='to' label="Od" min={response.filterValues.minDate}
                            max={response.filterValues.maxDate} onChange={onFilterChange}/>,
                <Forms.Select name='type' emptyOption options={typeOptions} label="Typ nákladu"
                              onChange={onFilterChange}/>,
                <Forms.Select name='vehicle' emptyOption options={vehicleOptions} label="Vozidlo"
                              onChange={onFilterChange}/>

            </div>;
        }
    }

    return (
        <div className="VehiclesMaintenance">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('vehicleMaintenance')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('lastAdded')} columns={columns}
                               rows={response?.overviewData} isLoading={isLoading} error={error}
                               tableActions={tableActions} filters={getFilters()}
                />
            </Overview>
        </div>
    );
}

export default VehiclesMaintenance;
