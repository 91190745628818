import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logo from '../img/logo-cele.png';
import {NavLink, useLocation} from "react-router-dom";
import config from "../config/config";
import t from "../hooks/useTranslation";

import './Navbar.css';

function Navbar() {

    const {pathname} = useLocation();

    const isActive = (route) => {
        return config.menuItems[route].some(regex => {
            return pathname.match(`^${regex}$`);
        });
    }

    const menuItem = (label, route, icon) => {
        return (
            <NavLink to={route} activeClassName='selected'
                     isActive={() => isActive(route)}
                     className='navbar-item'>
                <FontAwesomeIcon size={"lg"} icon={icon}/>
                <label>{label}</label>
            </NavLink>
        );
    }

    return (
        <div className="navbar">
            <img src={logo} alt="logo"/>
            <div>
                {menuItem(t('actualOverview'), config.routeMap.dashboard, 'clock')}
                {menuItem(t('vehicles'), config.routeMap.vehicles, 'truck')}
                {menuItem(t('vehicleMaintenance'), config.routeMap.vehiclesMaintenance, 'hand-holding-usd')}
                {menuItem(t('driversAdministration'), config.routeMap.drivers, 'users')}
                {menuItem(t('clientsAdministration'), config.routeMap.clients, 'address-book')}
                {menuItem(t('drivingRoutes'), config.routeMap.routes, 'route')}
            </div>
        </div>
    );
}

export default Navbar;
