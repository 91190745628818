import moment from "moment";
import * as React from "react";

import config from "../config/config";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import t from "../hooks/useTranslation";
import ClientForm from "../components/ClientForm";

import './ClientDetail.css';

const INITIAL_BRICKS_DATA = [
    {
        label: t('name'),
        isLoading: true,
        dataKey: 'name'
    },
    {
        label: t('createdBy'),
        isLoading: true,
        valueRenderer: ({createdBy}) => createdBy ? `${createdBy.name} ${createdBy.surname}` : 'import'
    },
    {
        label: t('createdAt'),
        isLoading: true,
        valueRenderer: ({createdAt}) => moment(createdAt).format('DD.MM.YYYY')
    },
    {
        label: t('visitedCount'),
        isLoading: true,
        dataKey: 'visitedCount'
    }
];

function ClientDetail(props) {
    const {
        isLoading,
        error,
        response,
        bricksData,
    } = useOverviewData(config.apiRouteMap.getClient(props.match.params.id), INITIAL_BRICKS_DATA);

    return (
        <div className="ClientDetail">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('clientDetail')}
                bricks={bricksData}
                error={error}
            >
                <ClientForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default ClientDetail;
