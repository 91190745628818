import * as React from "react";

import DriverForm from "../components/DriverForm";
import Overview from "../components/Overview";
import t from "../hooks/useTranslation";

import './DriverAdd.css';

function DriverAdd(props) {

    return (
        <div className="DriverAdd">
            <Overview
                introduction={t('introductionText')}
                title={t('newDriver')}
            >
                <DriverForm
                    history={props.history}
                />
            </Overview>
        </div>
    );
}

export default DriverAdd;
