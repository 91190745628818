import React, {useEffect, useState} from 'react';
import Loading from "./Loading";
import Error from "./Error";
import Forms from "../form/Forms";
import {onChange} from "../tools";
import AuthService from "../AuthService";
import config from "../config/config";
import useFetch from "../hooks/useFetch";
import t from "../hooks/useTranslation";

import './VehicleMaintenanceForm.css';

const authService = new AuthService();

const INITIAL_FORM_DATA = {
    documentDate: new Date().toISOString(),
    vehicle: '',
    priceWithVat: '',
    priceWithoutVat: '',
    type: '',
    description: ''
}

function VehicleMaintenanceForm(props) {
    const {
        isLoading: isVehiclesLoading,
        error: vehicleError,
        response: vehicleResponse
    } = useFetch(config.apiRouteMap.vehicle);
    const [formData, setFormData] = useState(INITIAL_FORM_DATA);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            setFormData(props.data);
        }
    }, [props.data]);

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            if (formData._id) {
                await authService.put(config.apiRouteMap.getVehicleMaintenance(formData._id), formData);
            } else {
                await authService.post(config.apiRouteMap.vehicleMaintenance, formData);
            }
            setError(null);
            props.history.push(config.routeMap.vehiclesMaintenance);
        } catch (err) {
            setIsLoading(false);
            setError(err);
        }
    }

    const getVehicleOptions = () => {
        return [{value: null, label: ''}, ...vehicleResponse.map(vehicle => ({
            value: vehicle._id,
            label: vehicle.licenceNumber
        }))];
    }

    const documentTypeOptions = [
        {label: '', value: null},
        {label: t('fuel'), value: 'fuel'},
        {label: t('service'), value: 'service'},
        {label: t('pneuservis'), value: 'pneu'},
        {label: t('other'), value: 'other'},
    ];

    const vatOptions = [
        {label: 21, value: 21},
        {label: 15, value: 15},
        {label: 10, value: 10},
        {label: '', value: null}
    ];

    const renderForm = () => {
        return (
            <>
                <div className="formContent">
                    <form onSubmit={onSubmit}>
                        <Forms.Date label={t('documentDate')} value={formData.documentDate} name='documentDate'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('vehicle')} value={formData.vehicle} name='vehicle'
                                      disabled={isLoading}
                                      onChange={onChange(setFormData)} options={getVehicleOptions()}/>
                        <Forms.Text label={t('documentPriceWithVat')} value={formData.priceWithVat} name='priceWithVat'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Text label={t('documentPriceWithoutVat')} value={formData.priceWithoutVat}
                                    name='priceWithoutVat'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                        <Forms.Select label={t('documentType')} value={formData.type} name='type' disabled={isLoading}
                                      onChange={onChange(setFormData)} options={documentTypeOptions}/>
                        <Forms.Select label={t('vat')} value={formData.vat} name='vat' disabled={isLoading}
                                      onChange={onChange(setFormData)} options={vatOptions}/>
                        <Forms.Text label={t('documnetDescription')} value={formData.description} name='description'
                                    disabled={isLoading} onChange={onChange(setFormData)}/>
                    </form>
                </div>
                {(error || vehicleError) && <Error error={error || vehicleError}/>}
                <div className="formActions">
                    <Forms.Button label={t('goBack')} onClick={props.history.goBack}/>
                    <Forms.Button label={_getButtonLabel()} onClick={onSubmit} disabled={isLoading}/>
                </div>
            </>
        );
    }

    const _getButtonLabel = () => {
        return formData._id ? t('save') : t('create');
    }

    const renderContent = () => {
        if (props.error) {
            return <Error error={props.error}/>
        } else {
            return renderForm();
        }
    };

    return (
        <div className="VehicleMaintenanceForm">
            {(props.isLoading || isVehiclesLoading) ? <div className="loading"><Loading/></div> : renderContent()}
        </div>
    );
}

export default VehicleMaintenanceForm;