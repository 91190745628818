import * as React from "react";

import Overview from "../components/Overview";
import t from "../hooks/useTranslation";

import './ClientAdd.css';
import ClientForm from "../components/ClientForm";

function ClientAdd(props) {

    return (
        <div className="ClientAdd">
            <Overview
                introduction={t('introductionText')}
                title={t('newClient')}
            >
                <ClientForm
                    history={props.history}
                />
            </Overview>
        </div>
    );
}

export default ClientAdd;
