import * as React from "react";
import config from "../config/config";
import moment from 'moment';

import Overview from "../components/Overview";
import useOverviewData from "../hooks/useOverviewData";
import OverviewTable from "../components/OverviewTable";
import {Link} from "react-router-dom";
import State from "../components/State";
import t from "../hooks/useTranslation";

import './Dashboard.css';

const INITIAL_BRICKS_DATA = [
    {
        label: t('vehicleCount'),
        isLoading: true,
        route: config.routeMap.vehicles,
        dataKey: 'vehicleCount'
    },
    {
        label: t('driverCount'),
        isLoading: true,
        route: config.routeMap.drivers,
        dataKey: 'driverCount'
    },
    {
        label: t('routeCount'),
        isLoading: true,
        route: config.routeMap.routes,
        dataKey: 'routeCount'
    },
    {
        label: t('vehicleMaintenancePriceCount'),
        isLoading: true,
        dataKey: 'vehicleMaintenancePriceCount',
        valueRenderer: ({vehicleMaintenancePriceCount}) => `${vehicleMaintenancePriceCount.toFixed(2)},-`
    }
];

function Dashboard() {
    const {isLoading, error, response, bricksData} = useOverviewData('/api/dashboard', INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'spz',
            title: t('spz'),
            linkRenderer: ({vehicle}) => ({
                route: config.routeMap.getVehicleDetail(vehicle._id),
                label: vehicle.licenceNumber
            })
        },
        {
            key: 'model',
            title: t('vehicleType')
        },
        {
            key: 'fuel',
            title: t('engine'),
            valueRenderer: ({vehicle}) => config.fuelMap[vehicle.fuel]
        },
        {
            key: 'driver',
            title: t('driver'),
            valueRenderer: ({driver}) => <Link to={config.routeMap.getDriverDetail(driver._id)}>
                {`${driver.name} ${driver.surname}`}
            </Link>
        },
        {
            key: 'startDate',
            title: t('start'),
            valueRenderer: ({startDate}) => {
                const formatString = moment(startDate).isSame(moment(), 'day') ? 'HH:mm' : 'DD.MM HH:mm';
                return `${moment(startDate).format(formatString)} hod.`;
            }
        },
        {
            key: 'endDate',
            title: t('end'),
            valueRenderer: ({endDate}) => {
                const formatString = moment(endDate).isSame(moment(), 'day') ? 'HH:mm' : 'DD.MM HH:mm';
                return endDate && `${moment(endDate).format(formatString)} hod.`;
            }
        },
        {
            key: 'state',
            title: t('state'),
            valueRenderer: ({state}) => <State state={state}/>
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="action" to={config.routeMap.getRouteHistoryDetail(_id)}>
                {t('ride')}
            </Link>
        }
    ];

    return (
        <div className="Dashboard">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('actualOverview')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('todayRides')} columns={columns} rows={response?.overviewData}
                               isLoading={isLoading} error={error}/>
            </Overview>
        </div>
    );
}

export default Dashboard;
