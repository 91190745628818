import * as React from "react";
import config from "../config/config";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import OverviewTable from "../components/OverviewTable";
import {Link} from "react-router-dom";
import moment from "moment";

import './Vehicles.css';
import t from "../hooks/useTranslation";

const INITIAL_BRICKS_DATA = [
    {
        label: t('vehicleCount'),
        isLoading: true,
        dataKey: 'vehicleCount'
    },
    {
        label: t('gasVehicleCount'),
        isLoading: true,
        dataKey: 'gasVehicleCount'
    },
    {
        label: t('dieselVehicleCount'),
        isLoading: true,
        dataKey: 'dieselVehicleCount'
    },
    {
        label: t('totalDistanceCount'),
        isLoading: true,
        dataKey: 'totalDistanceCount'
    }
];

function Vehicles() {
    const {isLoading, error, response, bricksData} = useOverviewData('/api/vehicle/dashboard', INITIAL_BRICKS_DATA);

    const columns = [
        {
            key: 'spz',
            title: t('spz'),
            linkRenderer: ({_id, licenceNumber}) => ({
                route: config.routeMap.getVehicleDetail(_id),
                label: licenceNumber
            })
        },
        {
            key: 'model',
            title: t('vehicleModel')
        },
        {
            key: 'fuel',
            title: t('engine'),
            valueRenderer: ({fuel}) => config.fuelMap[fuel]
        },
        {
            key: 'driver',
            title: t('driver'),
            valueRenderer: ({driver}) => driver && <Link to={config.routeMap.getDriverDetail(driver._id)}>
                {`${driver.name} ${driver.surname}`}
            </Link>
        },
        {
            key: 'routeCount',
            title: t('rideCount')
        },
        {
            key: 'stkExpirationDate',
            title: t('stkExpirationDate'),
            valueRenderer: ({stkExpirationDate}) => stkExpirationDate && `${moment(stkExpirationDate).format('DD.MM.YYYY')}`
        },
        {
            key: 'ac',
            title: t('ac'),
            valueRenderer: ({ac}) => ac ? t('yes') : t('no')
        }
    ];

    const actions = [
        {
            component: ({_id}) => <Link className="action1" to={config.routeMap.getVehicleDetail(_id)}>
                {t('detail')}
            </Link>
        },
        {
            component: ({_id}) => <Link className="action2" to={config.routeMap.getVehicleMaintenanceDetail(_id)}>
                {t('maintenance')}
            </Link>
        }
    ];

    const tableActions = [
        {
            component: <Link to={config.routeMap.addVehicle}>{t('newVehicle')}</Link>
        }
    ];

    return (
        <div className="Vehicles">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('vehiclePark')}
                bricks={bricksData}
                error={error}
            >
                <OverviewTable actions={actions} title={t('vehicleList')} columns={columns}
                               rows={response?.overviewData}
                               isLoading={isLoading} error={error} tableActions={tableActions}/>
            </Overview>
        </div>
    );
}

export default Vehicles;
