import moment from "moment";
import * as React from "react";
import config from "../config/config";
import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import t from "../hooks/useTranslation";
import RouteForm from "../components/RouteForm";

import './RouteDetail.css';

const INITIAL_BRICKS_DATA = [
    {
        label: t('routeName'),
        isLoading: true,
        dataKey: 'name'
    },
    {
        label: t('routeNumber'),
        isLoading: true,
        dataKey: 'routeNumber'
    },
    {
        label: t('driver'),
        isLoading: true,
        dataKey: 'driver',
        valueRenderer: () => ''
    },
    {
        label: t('vehicle'),
        isLoading: true,
        dataKey: 'vehicle',
        valueRenderer: () => ''
    }
];

function RouteDetail(props) {
    const {
        isLoading,
        error,
        response,
        bricksData,
    } = useOverviewData(config.apiRouteMap.getRoute(props.match.params.id), INITIAL_BRICKS_DATA);

    return (
        <div className="RouteDetail">
            <Overview
                isLoading={isLoading}
                introduction={t('introductionText')}
                title={t('routeDetail')}
                bricks={bricksData}
                error={error}
            >
                <RouteForm
                    history={props.history}
                    data={response}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default RouteDetail;
