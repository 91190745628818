import React from 'react';
import t from "../hooks/useTranslation";

import './State.css';

export default function State({state}) {
    const stateMap = {
        inProcess: t('inProcess'),
        finished: t('finished')
    };

    if (!stateMap[state]) {
        return '';
    }

    return (
        <div className="State">
            <label className={state}>{stateMap[state]}</label>
        </div>
    );
}