import * as React from "react";
import './Number.css';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Number(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    return (
        <label className="FormNumber">
            {props.label}
            <input type="number" {...getMainProps(inputMainProps)} disabled={disabled}/>
        </label>
    );
}

export default Number;