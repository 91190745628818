import decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import {axiosInstance} from "./axios";

class AuthService {
    // Initializing important variables
    constructor() {
        this.serverApiUrl = process.env.REACT_APP_SERVER_API_URL; // API server domain
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
    }

    async login(body) {
        // Get a token from api server using the fetch api
        try {
            const {data} = await this.post('/api/user/signin', body);
            return data;
        } catch (e) {
            throw e;
        }
    }

    async forgottenPassword(email) {
        // Get a token from api server using the fetch api
        try {
            const {data} = await this.post('/api/user/forgottenPassword', {email});
            return data;
        } catch (e) {
            throw e;
        }
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isAdmin() {
        // Checks if user is cast in submitted profile
        if (this.loggedIn()) {
            return this.getDecodedToken().right === 2
        }
        return false;
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000; // Checking if token is expired. N
        } catch (err) {
            return false;
        }
    }

    async setNewPassword(formData) {
        // Get a token from api server using the fetch api
        try {
            const {data} = await this.post('/api/setNewPassword', formData);
            return data;
        } catch (e) {
            throw e;
        }
    }

    _checkStatus(response, isFile) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            if (isFile) {
                return response.blob().then(blob => {
                    return blob;
                });
            } else {
                const isJson = response.headers.get('content-type')?.indexOf('application/json') !== -1;
                if (isJson) {
                    return response.json().then(json => {
                        return json;
                    });
                } else {
                    return response.text().then(text => {
                        return text;
                    })
                }
            }
        } else {
            return Promise.resolve(response.json()).then(err => {
                throw err;
            });
        }
    }

    // async fetch(url, options = {method: "GET"}) {
    //     // performs api calls sending the required authentication headers
    //     const headers = {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     };
    //     let isFile;
    //     if (options && options.type) {
    //         isFile = options.type === "file";
    //         delete options.type;
    //     }
    //
    //     // Setting Authorization header
    //     // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    //     if (this.loggedIn()) {
    //         headers['Authorization'] = 'Bearer ' + this.getToken();
    //     }
    //
    //     try {
    //         return await new Promise((resolve => {
    //             fetch(url, {
    //                 headers,
    //                 ...options
    //             }).then(response => {
    //                 resolve(this._checkStatus(response, isFile));
    //             }).catch(err => {
    //                 resolve(err);
    //             });
    //         }));
    //     } catch (e) {
    //         console.log(e);
    //         throw e;
    //     }
    // }


    getToken() {
        const tokenData = localStorage.getItem('id_token');
        return JSON.parse(tokenData).accessToken;
    }

    fetch(url, method = 'GET', formData = null, headers = null) {
        const options = {
            url,
            method,
            headers: {},
            data: formData
        };
        if (this.isLogged()) {
            options.headers['Authorization'] = `Bearer ${this.getToken()}`;
        }
        if (headers) {
            Object.entries(headers).forEach(([key, val]) => {
                options.headers[key] = val;
            });
        }
        return axiosInstance(options);
    }

    async post(url, formData, headers = {}) {
        try {
            return await this.fetch(url, 'POST', formData, headers);
        } catch (err) {
            throw err;
        }
    }

    async put(url, formData, headers = {}) {
        try {
            return await this.fetch(url, 'PUT', formData, headers);
        } catch (err) {
            throw err;
        }
    }

    async delete(url) {
        try {
            return await this.fetch(url, 'DELETE');
        } catch (err) {
            throw err;
        }
    }

    async get(url) {
        try {
            return await this.fetch(url, 'GET');
        } catch (err) {
            throw err;
        }
    }

    isLogged() {
        let token = localStorage.getItem('id_token');
        if (token) {
            const {exp} = jwtDecode(JSON.parse(token).accessToken);
            const expirationTime = (exp * 1000) - 60000;
            if (Date.now() >= expirationTime) {
                localStorage.clear();
                return false;
            }
            return true;
        }
        return false;
    }

}

export default AuthService;