import './LoginButton.css';

function LoginButton(props) {
    let className = "LoginButton";
    if (props.className) {
        className += ` ${props.className}`;
    }

    return (
        <button className={className} onClick={props.onClick}>
            {props.label}
        </button>
    );
}

export default LoginButton;