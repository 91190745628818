import {useState} from "react";
import * as React from "react";
import Logo from '../img/logo-1.png';
import Forms from '../form/Forms';
import AuthService from "../AuthService";
import Error from "../components/Error";
import './Login.css';
import t from "../hooks/useTranslation";
import {useAuth} from "../context/AuthContext";
import config from "../config/config";
import {useHistory} from "react-router-dom";

const authService = new AuthService();

function ForgottenPasswordContent() {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState();

    const submit = async () => {
        setSuccess();
        setError();
        try {
            await authService.forgottenPassword(email);
            setSuccess(t('emailSent'));
        } catch (err) {
            setError(err);
        }
    }

    const handleKeyPress = (e) => {
        if (e.charCode === 13) {
            submit();
        }
    }

    return (
        <div className="left-container">

            <img className="logo" src={Logo} alt="logo"/>

            <h2>{t('logInToApplication')}</h2>

            <div className="input-container">
                <label>
                    {t('loginName')}
                    <input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)}
                           onKeyPress={handleKeyPress}/>
                </label>
            </div>

            <div className="login-row">
                <div className="remember"/>

                <label className="forgotten-password"
                       onClick={() => history.push(config.routeMap.login)}>{t('backToLogin')}</label>
            </div>

            <Forms.LoginButton label={t('sendResetLink')} onClick={submit}/>
            {error && <Error error={error}/>}
            {success && <label className="success-message">{success}</label>}


        </div>
    );
}

export default ForgottenPasswordContent;
