import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Loading from "./Loading";

import './OverviewBrick.css';

function OverviewBrick({data}) {

    const renderContent = () => {
        return <label className="value">{data.data}</label>;
    }

    const renderRoute = () => {
        return (
            <Link className="OverviewBrick pointer" to={data.route}>
                <div>
                    <label className="label">{data.label}</label>
                    {data.isLoading ? <Loading/> : renderContent()}
                </div>
                <FontAwesomeIcon size={"lg"} icon="chevron-right"/>
            </Link>
        );
    };

    const renderBrick = () => {
        return (
            <div className="OverviewBrick">
                <div>
                    <label className="label">{data.label}</label>
                    {data.isLoading ? <Loading/> : renderContent()}
                </div>
            </div>
        );
    };

    return data.route ? renderRoute() : renderBrick();
}

export default OverviewBrick;