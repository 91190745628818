import {useEffect, useState} from 'react';
import AuthService from "../AuthService";
import {useAuth} from "../context/AuthContext";

const authService = new AuthService();

export default function useFetch(url, method, formData, headers) {
    const [error, setError] = useState(null);
    const {logout} = useAuth();
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const {data} = await authService.fetch(url, method, formData, headers);
                setResponse(data);
                setIsLoading(false);
                setError(null);
            } catch (error) {
                console.error(error);
                setError(error);
                setIsLoading(false);
                if (error.response.data.errorCode === "permissionDenied") {
                    alert('Pro načtení této stránky nemáte dostatečná oprávnění. Obraťte se na správce aplikace. Nyní budete odhlášení.');
                    logout();
                }
            }
        })();
    }, []);

    return {
        isLoading,
        error,
        response
    }
}
