import moment from "moment";
import * as React from "react";

import useOverviewData from "../hooks/useOverviewData";
import Overview from "../components/Overview";
import VehicleForm from "../components/VehicleForm";

import './VehicleDetail.css';
import config from "../config/config";

const INITIAL_BRICKS_DATA = [
    {
        label: 'SPZ vozu',
        isLoading: true,
        dataKey: 'licenceNumber'
    },
    {
        label: 'Náklady na PHM (Kč)',
        isLoading: true,
        dataKey: 'gasPriceCount'
    },
    {
        label: 'Náklady ostatní (Kč)',
        isLoading: true,
        dataKey: 'otherPriceCount'
    },
    {
        label: 'STK platná do',
        isLoading: true,
        dataKey: 'stkExpirationDate',
        valueRenderer: val => moment(val).format('DD.MM.YYYY')
    }
];

function VehicleDetail(props) {
    const {
        isLoading,
        error,
        response,
        bricksData,
        setBrickDataByDataKey
    } = useOverviewData(config.apiRouteMap.getVehicle(props.match.params.id), INITIAL_BRICKS_DATA);

    return (
        <div className="VehicleDetail">
            <Overview
                isLoading={isLoading}
                introduction="Vítejte v administračním prostředí pro správu a plánování cestovních informací značky Rozvozovka.cz"
                title="Detail vozu"
                bricks={bricksData}
                error={error}
            >
                <VehicleForm
                    setBrickDataByDataKey={setBrickDataByDataKey}
                    history={props.history}
                    data={response?.data}
                    isLoading={isLoading}
                    error={error}
                />
            </Overview>
        </div>
    );
}

export default VehicleDetail;
